/* 
  Color pallet 
  #f4f5f4 -> White
  #9D9CAB -> Grey
  #3D6567 -> Turquoise
  #B66A5E -> Red
  #C7C15D -> Yellow
  #ADD8E6 -> Light Blue
  #d8fcff -> Snow Blue
*/
html, body {
  margin:0;
  padding: 0;
  height: 330%;
  overflow-x: hidden;
  overflow-y: scroll;
  background: #9D9CAB;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;

}

/****************Card page ********************/

.container {
    display: flex;
    align-items: center;
    height: 100vh;
    justify-content: center;

  }

.deck {
  position: absolute;
  width: 600px;
  height: 300px;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
  touch-action: none;
  /*top: 25%;
  transform: translateY(-50%)
  */
}
  
.deck > div {
  
  background-size: auto 85%;
  background-repeat: no-repeat;
  background-position: center center;
  width: 80vh;
  max-width: 600px;
  height: 600vh;
  max-height: 600px;
  will-change: transform;
  border-radius: 10px;
  box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4), 0 10px 10px -10px rgba(50, 50, 73, 0.3);
  /* from: https://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  text-align: center;

}

.deck > div h2 {
  font-size: 45px;
  padding-top: 30px;
  padding-bottom: 25px;
}

.outerCard {

  padding: 1%;
  height: 100%;

}

.middleCard {

  /* background-color: #C7C15D; */
  margin: 1%;
  padding: .1%;

}

.innerCard {

  background-color: #f4f5f4;

}
.cardcontent {

  margin-left: 15%;
  margin-right: 15%;

}

.yellowHorLine {

  display: flex;
  flex-direction: row;
  align-items: center;
  height: 1px;
  width: 100%;
  background-color: #C7C15D;

}

/********************** Middle page ***********************/
.middleouterbox {

  position: center;
  height: 100vh;
  width: 100%;
  display: flex;
  background-color:  #0D1821;
  align-items: center;
  justify-content: center;
  
}
  
.middlecontainer {
    
  height: 95%;
  width:95%;
  border-radius: 2%;
  background-color: #334966;
  box-shadow:rgba(50, 50, 73, 0.5);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1%;

}

.middlecontainer > section {

  height: 75%;
  width: 38%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5%, 5%;
  padding: 5%;
  background-color:#ABA9BF;
  border-radius: 2%;
  gap: 7%;
    
}

.middlecontainer > section > div {

  justify-content: center;
  align-items: center;
  height: 33%;
  width: 100%;
  background-color: #BEB7DF;
  border-radius: 2%;
  box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.695), 0 10px 10px -10px rgba(50, 50, 73, 0.3);
  
}

.middleWidget {

  height: 33%;
  padding: 1%;

}

.todoDiv {

  display: flex;
  flex-direction: row;
  justify-content: space-around;

}

.todoInput {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.todoTasksDisplay {

  display: flex;
  flex-direction: column;
  height: 190px;
  overflow-y: auto;
  padding: 1%;
  
}

.outerPhoto {

  height: 75%;
  width: 38%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5%;
  background-color:#9D9CAB;
  border-radius: 2%;

}
.photoContainer {

  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 2%;


}

.weatherDiv {

  display: flex;
  justify-content: space-around;
  overflow-y: hidden;

}

.weatherImageDiv {

  background-color: #9D9CAB;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 10%;
  height: 10%;
  border-radius: 20%;
  box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.695), 0 10px 10px -10px rgba(50, 50, 73, 0.3);;

}


/************************* About me page **********************************/
.aboutMeContainer {
  height: 100vh;
  background-color: #0D1821;
  display: flex;
  justify-content: space-around;
}

.aboutMeContainer > div {
  background-color: #334966; /* Use Turquoise for the main div background */
  width: 40%;
  margin: 2%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 5%;
  box-sizing: border-box;
  box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.695), 0 10px 10px -10px rgba(50, 50, 73, 0.3);
}

.aboutMeContainer > div > .tallSection {
  justify-self: center;
  position: relative;
  width: 85%;
  height: 75%;
  background-color: #C7C15D; /* Use Yellow for the tall section background */
  display: flex;
  flex-direction: row;
  border-radius: 5%;
}

.aboutMeContainer > div > .smallSection {
  width: 90%;
  height: 15%;
  background-color: #BEB7DF;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2%;
}

.aboutMeContainer > div > .smallSection > h2 {
  display: flex;
  justify-content: center;
  top: 50%;
  margin: 0%;
}

.aboutMeContainer > div > .tallSection > .line {
  width: 10px;
  background-color: #9D9CAB;
}

.aboutMeContainer > div > .tallSection > .boldTextSection {
  margin: 3%;
  display: flex;
  flex-direction: column;
  background-color: #C7C15D; 
  max-width: fit-content;
}

.aboutMeContainer > div > .tallSection > .innerText {
  background-color: #f4f5f4;
  font-size: large;
  display: flex;
  border-radius: 0 5% 5% 0;
  width: 100%;
  overflow: scroll;
}

.aboutMeContainer > div > .smallSection > .innerText > p {
  font-size: small;
}

.in {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 15%;

}

.header, .footer {

  background-color: #2c2c2c;
  color:#C7C15D;
  justify-content: center;

}

.footer {

  position: absolute;
  width: 100%;
  padding:1em;
}

.headerSections, .footerSections{

  display: flex;
  flex-direction: row;
  height: 15vh;
  align-items: center;
  padding:0% 3%;

}

.headerTitle {

  color:#C7C15D;
  text-decoration: none;
}

.headerLinks {

  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap:10%;
  justify-content:first baseline;

}

/* ************************** New landing page ************************* */

.background {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #0D1821; /* Soft neutral beige */
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../pictures/pexels-anniroenkae-2832382.jpg");
  width: 100%;
  align-items: center;
  justify-content: center;
}

.infoContainer {
  background-color: #334966; /* Light greyish mint */
  width: 50%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.infoTop {
  background-color: #ABA9BF; /* Muted slate blue */
  width: 95%;
  height: 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 10px;
  color: #ffffff; /* White text */
}

.infoBottom {
  background-color: #ABA9BF;
  width: 90%;
  height: 30%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-radius: 10px;
  color: #ffffff; /* White text */
}

.navButtonBase {
  background-color: #0D1821; /* Muted slate blue */
  width: 28%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.navButton {
  background-color: #0D1821;
  display:flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  width: 60%;
  border-radius: 5%;
  transition: background-color, font-size 0.3s ease;

}

.navButtonBase:hover .navButton {
  background-color: #1a2739; /* Soft desaturated green */
  font-size: large;
}

.infoWelcomeTop, .infoWelcomeBottom {
  /* background-color: #f0f0f5; /* Soft neutral beige */
  width: 95%;
  height: 22%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  color: #2c3e50; /* Dark charcoal grey for readability */
  border-radius: 10px;
  padding: 10px;
}

@media (max-width: 1500px) {
  .infoWelcomeTop {
    font-size: 6vw; /* Scales based on viewport width */
  }
  .infoWelcomeBottom {
    font-size: 4.5vw;
    display: flex;
    }
}

@media (min-width: 1500px) {
  .infoWelcomeTop {
    font-size: 4rem; /* Fixed size based on root font size */
  }
  .infoWelcomeBottom {
    font-size: 3rem;
    text-align: center;
  }
}

